.glitch {
    position: relative;
    color: white;
    letter-spacing: 0.5em;
    animation: glitch-skew 1s infinite linear alternate-reverse;
}

.glitch::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: 2px;
    text-shadow: -2px 0 #ff00c1;
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim 5s infinite linear alternate-reverse;
}

.glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: -2px;
    text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
    animation: glitch-anim2 1s infinite linear alternate-reverse;
}

@keyframes glitch-anim {
    0% {
        clip: rect(16px, 9999px, 45px, 0);
        transform: skew(0.29deg);
    }
    5% {
        clip: rect(47px, 9999px, 100px, 0);
        transform: skew(0.5deg);
    }
    10% {
        clip: rect(91px, 9999px, 40px, 0);
        transform: skew(0.46deg);
    }
    15% {
        clip: rect(95px, 9999px, 6px, 0);
        transform: skew(0.95deg);
    }
    20% {
        clip: rect(65px, 9999px, 68px, 0);
        transform: skew(0.57deg);
    }
    25% {
        clip: rect(33px, 9999px, 47px, 0);
        transform: skew(0.04deg);
    }
    30% {
        clip: rect(10px, 9999px, 51px, 0);
        transform: skew(0.19deg);
    }
    35% {
        clip: rect(14px, 9999px, 23px, 0);
        transform: skew(0.97deg);
    }
    40% {
        clip: rect(72px, 9999px, 60px, 0);
        transform: skew(0.54deg);
    }
    45% {
        clip: rect(29px, 9999px, 37px, 0);
        transform: skew(0.4deg);
    }
    50% {
        clip: rect(35px, 9999px, 15px, 0);
        transform: skew(0.67deg);
    }
    55% {
        clip: rect(30px, 9999px, 99px, 0);
        transform: skew(0.25deg);
    }
    60% {
        clip: rect(71px, 9999px, 72px, 0);
        transform: skew(0.93deg);
    }
    65% {
        clip: rect(41px, 9999px, 68px, 0);
        transform: skew(0.41deg);
    }
    70% {
        clip: rect(10px, 9999px, 34px, 0);
        transform: skew(0.28deg);
    }
    75% {
        clip: rect(23px, 9999px, 35px, 0);
        transform: skew(0.05deg);
    }
    80% {
        clip: rect(28px, 9999px, 59px, 0);
        transform: skew(0.82deg);
    }
    85% {
        clip: rect(95px, 9999px, 6px, 0);
        transform: skew(0.75deg);
    }
    90% {
        clip: rect(88px, 9999px, 85px, 0);
        transform: skew(0.12deg);
    }
    95% {
        clip: rect(36px, 9999px, 47px, 0);
        transform: skew(0.04deg);
    }
    100% {
        clip: rect(2px, 9999px, 22px, 0);
        transform: skew(0.52deg);
    }
}

@keyframes glitch-anim2 {
    0% {
        clip: rect(10px, 9999px, 73px, 0);
        transform: skew(0.08deg);
    }
    5% {
        clip: rect(68px, 9999px, 86px, 0);
        transform: skew(0.9deg);
    }
    10% {
        clip: rect(72px, 9999px, 7px, 0);
        transform: skew(0.7deg);
    }
    15% {
        clip: rect(59px, 9999px, 1px, 0);
        transform: skew(0.46deg);
    }
    20% {
        clip: rect(70px, 9999px, 42px, 0);
        transform: skew(0.23deg);
    }
    25% {
        clip: rect(50px, 9999px, 7px, 0);
        transform: skew(0.65deg);
    }
    30% {
        clip: rect(29px, 9999px, 87px, 0);
        transform: skew(0.49deg);
    }
    35% {
        clip: rect(49px, 9999px, 41px, 0);
        transform: skew(0.43deg);
    }
    40% {
        clip: rect(77px, 9999px, 11px, 0);
        transform: skew(0.78deg);
    }
    45% {
        clip: rect(98px, 9999px, 68px, 0);
        transform: skew(0.15deg);
    }
    50% {
        clip: rect(11px, 9999px, 72px, 0);
        transform: skew(0.1deg);
    }
    55% {
        clip: rect(84px, 9999px, 50px, 0);
        transform: skew(0.41deg);
    }
    60% {
        clip: rect(45px, 9999px, 57px, 0);
        transform: skew(0.47deg);
    }
    65% {
        clip: rect(79px, 9999px, 98px, 0);
        transform: skew(0.72deg);
    }
    70% {
        clip: rect(20px, 9999px, 2px, 0);
        transform: skew(0.89deg);
    }
    75% {
        clip: rect(91px, 9999px, 44px, 0);
        transform: skew(0.75deg);
    }
    80% {
        clip: rect(23px, 9999px, 86px, 0);
        transform: skew(0.43deg);
    }
    85% {
        clip: rect(30px, 9999px, 2px, 0);
        transform: skew(0.77deg);
    }
    90% {
        clip: rect(60px, 9999px, 84px, 0);
        transform: skew(0.18deg);
    }
    95% {
        clip: rect(100px, 9999px, 32px, 0);
        transform: skew(0.56deg);
    }
    100% {
        clip: rect(90px, 9999px, 30px, 0);
        transform: skew(0.4deg);
    }
}

@keyframes glitch-skew {
    0% {
        transform: skew(0deg);
    }
    10% {
        transform: skew(0deg);
    }
    20% {
        transform: skew(1deg);
    }
    30% {
        transform: skew(5deg);
    }
    40% {
        transform: skew(-1deg);
    }
    50% {
        transform: skew(4deg);
    }
    60% {
        transform: skew(0deg);
    }
    70% {
        transform: skew(2deg);
    }
    80% {
        transform: skew(-4deg);
    }
    90% {
        transform: skew(-4deg);
    }
    100% {
        transform: skew(0deg);
    }
}