.grid-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: black;
    overflow: hidden;
}

.grid-container:after {
    -webkit-mask-image: -webkit-gradient(linear, left 90%, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: gradient(linear, left 90%, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    transform: perspective(200px) rotateX(40deg) scale(2, 1) translateZ(0);
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    padding: 1px;
    -webkit-background-clip: content-box;
    -webkit-backface-visibility: hidden;
    outline: 1px solid transparent;
    transform-origin: bottom center;
    will-change: transform;
}

.grid-container:after {
    background-position: center bottom;
    background-size: 40px 40px;
    background-image: linear-gradient(to right, red 2px, transparent 2px),
    linear-gradient(to bottom, red 1px, transparent 2px);
}